<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-8"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="รายละเอียดสติ้กเกอร์"
        v-model="formData.description"
        autoGrow
        :validations="[
          { text: 'required!', value: v.formData.description.$dirty && v.formData.description.$error }
        ]">
      </sgv-input-textarea>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="URL"
        v-model="formData.url"
        placeholder="https://example.com/coupon"
        :validations="[
          { text: 'required!', value: v.formData.url.$dirty && v.formData.url.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-datetime
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-6"
        label="วันเริ่มกิจกรรม"
        v-model="formData.startedAt"
        :validations="[
          { text: 'required!', value: v.formData.startedAt.$dirty && v.formData.startedAt.$error}
        ]">
      </sgv-input-datetime>

      <sgv-input-datetime
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-6"
        label="สิ้นสุดกิจกรรม"
        v-model="formData.expiredAt"
        :validations="[
          { text: 'required!', value: v.formData.expiredAt.$dirty && v.formData.expiredAt.$error }
        ]">
      </sgv-input-datetime>
    </div>

    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-4"
        label="เอกสาร"
        placeholder="iv,riv..."
        v-model="formData.docTypes">
      </sgv-input-text>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-4"
        label="จำนวนคูปองทั้งหมด"
        v-model="formData.ticketTotal">
      </sgv-input-number>

      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-6 col-sm-4"
        label="จำนวนคูปองต่อบอร์ด"
        v-model="formData.ticketSample">
      </sgv-input-number>
    </div>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    couponId: {
      type: String,
      required: true
    },
    couponType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    }
  },
  data () {
    return {

    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    }
  }
}
</script>

<style lang="css">
</style>
