import gql from 'graphql-tag'


export const LIST_TICKET = (templateType) => gql`query LIST_TICKET ($couponType: String!, $couponId: ID!) {
  tickets: list${templateType}Ticket (couponType: $couponType, couponId: $couponId) {
    id type name sample couponId
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
    activatedAt
    closedAt closedBy closedUser {id name}
    inventoryId inventory {
      id type code name
      unitId unit {id name}
    }
    docs {
      id type code name 
      contactId contact {
        id type code name branch
      }
    }
  }
}`

export const GENERATE_TICKET = (templateType) => gql`mutation GENERATE_TICKET ($couponType: String!, $couponId: ID!) {
  generateTicket: generate${templateType}Ticket (couponType: $couponType, couponId: $couponId)
}`

export const REVOKE_TICKET = (templateType) => gql`mutation REVOKE_TICKET ($couponType: String!, $couponId: ID!) {
  revokeTicket: revoke${templateType}Ticket (couponType: $couponType, couponId: $couponId)
}`

export const DEACTIVATE_TICKET = (templateType) => gql`mutation DEACTIVATE_TICKET ($couponType: String!, $couponTicketId: ID!) {
  deactivateTicket: deactivate${templateType}Ticket (couponType: $couponType, couponTicketId: $couponTicketId) {
    id
  }
}`

export const CLOSE_TICKET = (templateType) => gql`mutation CLOSE_TICKET ($couponType: String!, $couponTicketId: ID!) {
  closeTicket: close${templateType}Ticket (couponType: $couponType, couponTicketId: $couponTicketId) {
    id
  }
}`

export const ROLLBACK_TICKET = (templateType) => gql`mutation ROLLBACK_TICKET ($couponType: String!, $couponTicketId: ID!) {
  rollbackTicket: rollback${templateType}Ticket (couponType: $couponType, couponTicketId: $couponTicketId) {
    id
  }
}`