import gql from 'graphql-tag'


const listResponse = `
  id type code name
  docTypes ticketTotal ticketSample
  startedAt expiredAt
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
`

export const LIST_COUPON = (templateType) => gql`query LIST_COUPON ($couponType: String!, $q: FilterInput) {
  coupons: list${templateType} (couponType: $couponType, q: $q) {${listResponse}}
}`

export const WATCH_COUPONS_CREATED = (templateType) => gql`subscription WATCH_COUPONS_CREATED ($couponType: String!) {
  couponCreated: watch${templateType}Created (couponType: $couponType) {${listResponse}}
}`

export const WATCH_COUPONS_UPDATED = (templateType) => gql`subscription WATCH_COUPONS_UPDATED ($couponType: String!) {
  couponUpdated: watch${templateType}Updated (couponType: $couponType) {${listResponse}}
}`

export const WATCH_COUPONS_DESTROYED = (templateType) => gql`subscription WATCH_COUPONS_DESTROYED ($couponType: String!) {
  couponDestroyed: watch${templateType}Destroyed (couponType: $couponType) {id}
}`

const detailResponse = `
  id type code name description url
  docTypes ticketTotal ticketSample
  startedAt expiredAt
  createdAt createdBy createdUser {id name}
  approvedAt approvedBy approvedUser {id name}
  closedAt closedBy closedUser {id name}
`

export const DETAIL_COUPON = (templateType) => gql`query COUPON ($couponType: String!, $couponId: ID!) {
  coupon: detail${templateType} (couponType: $couponType, couponId: $couponId) {${detailResponse}}
}`

export const WATCH_COUPON_UPDATED = (templateType) => gql`subscription WATCH_COUPON_UPDATED ($couponType: String!, $couponId: ID) {
  couponUpdated: watch${templateType}Updated (couponType: $couponType, couponId: $couponId) {${detailResponse}}
}`

export const CREATE_COUPON = (templateType) => gql`mutation CREATE_COUPON ($couponType: String!, $input: ${templateType}Input!) {
  coupon: create${templateType} (couponType: $couponType, input: $input) {id}
}`

export const UPDATE_COUPON = (templateType) => gql`mutation UPDATE_COUPON ($couponType: String!, $couponId: ID!, $input: ${templateType}Input!) {
  coupon: update${templateType} (couponType: $couponType, couponId: $couponId, input: $input) {id}
}`

export const DESTROY_COUPON = (templateType) => gql`mutation DESTROY_COUPON ($couponType: String!, $couponId: ID!) {
  coupon: destroy${templateType} (couponType: $couponType, couponId: $couponId) {id}
}`

export const APPROVE_COUPON = (templateType) => gql`mutation APPROVE_COUPON ($couponType: String!, $couponId: ID!, $approvedAt: DateTime!) {
  coupon: approve${templateType} (couponType: $couponType, couponId: $couponId, approvedAt: $approvedAt) {id}
}`

export const CANCEL_COUPON = (templateType) => gql`mutation CANCEL_COUPON ($couponType: String!, $couponId: ID!) {
  coupon: cancel${templateType} (couponType: $couponType, couponId: $couponId) {id}
}`

export const CLOSE_COUPON = (templateType) => gql`mutation CLOSE_COUPON ($couponType: String!, $couponId: ID!, $closedAt: DateTime!) {
  coupon: close${templateType} (couponType: $couponType, couponId: $couponId, closedAt: $closedAt) {id}
}`

export const ROLLBACK_COUPON = (templateType) => gql`mutation ROLLBACK_COUPON ($couponType: String!, $couponId: ID!) {
  coupon: rollback${templateType} (couponType: $couponType, couponId: $couponId) {id}
}`