var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6 col-sm-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส"},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-text',{staticClass:"col-6 col-sm-8",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.name.$dirty && _vm.v.formData.name.$error}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-textarea',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รายละเอียดสติ้กเกอร์","autoGrow":"","validations":[
        { text: 'required!', value: _vm.v.formData.description.$dirty && _vm.v.formData.description.$error }
      ]},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"URL","placeholder":"https://example.com/coupon","validations":[
        { text: 'required!', value: _vm.v.formData.url.$dirty && _vm.v.formData.url.$error}
      ]},model:{value:(_vm.formData.url),callback:function ($$v) {_vm.$set(_vm.formData, "url", $$v)},expression:"formData.url"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-datetime',{staticClass:"col-12 col-sm-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"วันเริ่มกิจกรรม","validations":[
        { text: 'required!', value: _vm.v.formData.startedAt.$dirty && _vm.v.formData.startedAt.$error}
      ]},model:{value:(_vm.formData.startedAt),callback:function ($$v) {_vm.$set(_vm.formData, "startedAt", $$v)},expression:"formData.startedAt"}}),_c('sgv-input-datetime',{staticClass:"col-12 col-sm-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"สิ้นสุดกิจกรรม","validations":[
        { text: 'required!', value: _vm.v.formData.expiredAt.$dirty && _vm.v.formData.expiredAt.$error }
      ]},model:{value:(_vm.formData.expiredAt),callback:function ($$v) {_vm.$set(_vm.formData, "expiredAt", $$v)},expression:"formData.expiredAt"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-12 col-sm-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"เอกสาร","placeholder":"iv,riv..."},model:{value:(_vm.formData.docTypes),callback:function ($$v) {_vm.$set(_vm.formData, "docTypes", $$v)},expression:"formData.docTypes"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"จำนวนคูปองทั้งหมด"},model:{value:(_vm.formData.ticketTotal),callback:function ($$v) {_vm.$set(_vm.formData, "ticketTotal", $$v)},expression:"formData.ticketTotal"}}),_c('sgv-input-number',{staticClass:"col-6 col-sm-4",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"จำนวนคูปองต่อบอร์ด"},model:{value:(_vm.formData.ticketSample),callback:function ($$v) {_vm.$set(_vm.formData, "ticketSample", $$v)},expression:"formData.ticketSample"}})],1),_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-4",attrs:{"disabled":"","label":"สร้าง","value":_vm.getState('created')}}),_c('sgv-input-text',{staticClass:"col-4",attrs:{"disabled":"","label":"อนุมัติ","value":_vm.getState('approved')}}),_c('sgv-input-text',{staticClass:"col-4",attrs:{"disabled":"","label":"จบ","value":_vm.getState('closed')}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }