<template>
  <div class="row">
    <div class="col-12">
      <div class="form-row">
        <sgv-input-date
          label="วันที่อนุมัติ"
          v-model="approvedDate"
          class="col-12 col-md-4"
          :validations="[
            {text: 'required!', value: $v.approvedDate.$dirty && $v.approvedDate.$error}
          ]">
        </sgv-input-date>
      </div>
    </div>

  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { APPROVE_COUPON } from './graph/coupon'

export default {
  props: {
    couponType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    couponId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      approvedDate: this.$date.format(new Date()).date
    }
  },
  validations: {
    approvedDate: { required }
  },
  methods: {
    approveData () {
      this.$v.approvedDate.$touch()
      if (this.$v.approvedDate.$invalid) return

      this.$apollo.mutate({
        mutation: APPROVE_COUPON(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId,
          approvedAt: this.$date.format(this.approvedDate).utc
        }
      })
      .then(() => {
        this.$emit('update', null)
        this.$toasted.global.success("อนุมัติสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
  }
}
</script>

<style lang="css" scoped>
</style>
