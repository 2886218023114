import gql from 'graphql-tag'

const listResponse = `
  id type name isDefault
  inventoryId inventory {
    id type code name
    unitId unit {id name}
  }
  qty price
`

export const LIST_REWARD = (templateType) => gql`query LIST_REWARD ($couponType: String!, $couponId: ID!) {
  rewards: list${templateType}Reward (couponType: $couponType, couponId: $couponId) {${listResponse}}
}`

export const WATCH_REWARD_CREATED = (templateType) => gql`subscription WATCH_REWARD_CREATED ($couponType: String!, $couponId: ID!) {
  rewardCreated: watch${templateType}RewardCreated (couponType: $couponType, couponId: $couponId) {${listResponse}}
}`

export const WATCH_REWARD_DESTROYED = (templateType) => gql`subscription WATCH_REWARD_DESTROYED ($couponType: String!, $couponId: ID!) {
  rewardDestroyed: watch${templateType}RewardDestroyed (couponType: $couponType, couponId: $couponId) {id}
}`

export const CREATE_REWARD = (templateType) => gql`mutation CREATE_REWARD ($couponType: String!, $couponId: ID!, $input: ${templateType}RewardInput!) {
  reward: create${templateType}Reward (couponType: $couponType, couponId: $couponId, input: $input) {id}
}`

export const DESTROY_REWARD = (templateType) => gql`mutation DESTROY_REWARD ($couponType: String!, $couponRewardId: ID!) {
  reward: destroy${templateType}Reward (couponType: $couponType, couponRewardId: $couponRewardId) {id}
}`

export const LIST_DROPDOWN_INVENTORY = (templateType) => gql`query LIST_DROPDOWN_INVENTORY ($couponType: String!, $q: FilterInput) {
  items: list${templateType}RewardDropdownInventory (couponType: $couponType, q: $q) {
    id type code name
    unitId unit {id name}
  }
}`