<template>
  <sgv-table
    :items="tickets"
    :headers="headers"
    :filter.sync="filter"
    :options.sync="options">

    <template slot-scope="{item, hidden}">
      <tr>
        <td>
          ({{ item.sample }}) {{ item.id }}
          <small>
            <div 
              v-if="item.approvedAt && !item.activatedAt"
              @click="getLink(item.id)" 
              class="text-success pointer">
              คัดลอก Link
            </div>
          </small>
        </td>
        <td class="pre-line">
          {{item.name || '-'}}
          <small v-if="item.inventory">
            <div class="text-primary">
              {{item.inventory.code}} ({{item.inventory.name}}) ({{ item.inventory.unit.name }})
            </div>
          </small>
        </td>
        <td>
          <div v-for="doc in item.docs" :key="doc.id">
            <router-link
              :to="toDoc(doc)"
              class="text-decoration-none">
              {{doc.code}}
            </router-link>
            <small v-if="doc.contact" class="text-primary">
              <div>{{ doc.contact.code }} ({{ doc.contact.name }})</div>
            </small>
          </div>
        </td>
        <td v-if="hidden.createdAt">
          <span>{{ item.createdAt | date}}</span><br>
          <small class="text-primary">{{item.createdUser.name}}</small>
        </td>
        <td v-if="hidden.approvedAt">
          <span v-if="item.approvedAt">
            <span class="text-warning">{{item.approvedAt | date}}</span><br>
            <small class="text-primary">{{item.approvedUser.name}}</small>
          </span>
        </td>
        <td v-if="hidden.activatedAt">
          <span class="text-info">{{ item.activatedAt | date }}</span>
        </td>
        <td v-if="hidden.closedAt">
          <span v-if="item.closedAt">
            <span class="text-success">{{item.closedAt | date}}</span><br>
            <small class="text-primary">{{item.closedUser.name}}</small>
          </span>
        </td>
        <td v-if="hidden.action">
          <div>
            <button 
              type="button"
              class="btn btn-danger" 
              @dblclick="rollbackTicket(item.id)"
              v-if="item.closedAt && $auth.hasRole(`coupon:${couponType}:approve`)">
              ยกเลิกจัดส่ง
            </button>
            <button 
              type="button"
              class="btn btn-danger" 
              @dblclick="deactivateTicket(item.id)"
              v-if="item.activatedAt && !item.closedAt && $auth.hasRole(`coupon:${couponType}:approve`)">
              ยกเลิกการสุ่ม
            </button>
            <button 
              type="button"
              class="btn btn-success" 
              @dblclick="closeTicket(item.id)"
              v-if="item.activatedAt && !item.closedAt && !item.inventoryId && $auth.hasRole(`coupon:${couponType}:approve`)">
              จัดส่งสินค้า
            </button>
          </div>
        </td>
      </tr>
    </template>

    <div slot="action" v-if="!formData.approvedAt">
      <button 
        type="button" 
        class="btn btn-success mr-2"
        @dblclick="generateTicket">
        Generate
      </button>

      <button 
        type="button" 
        class="btn btn-danger"
        @dblclick="revokeTicket">
        Revoke
      </button>
    </div>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import {
  LIST_TICKET,
  GENERATE_TICKET,
  REVOKE_TICKET,
  DEACTIVATE_TICKET,
  ROLLBACK_TICKET,
  CLOSE_TICKET
} from './graph/ticket'

export default {
  mixins: [retainMixin],
  props: {
    couponId: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    couponType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      rKey: `Coupon${this.$form.capitalize(this.couponType)}DetailTicket`,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'รางวัล', value: 'name' },
        { text: 'เอกสาร', value: 'docs' },
        { text: 'วันที่สร้าง', value: 'createdAt' },
        { text: 'วันที่อนุมัติ', value: 'approvedAt' },
        { text: 'วันที่สุ่ม', value: 'activatedAt' },
        { text: 'วันที่จัดส่ง', value: 'closedAt' },
        { text: 'Action', value: 'action' },
      ],
      filter: {
        limit: null,
        offset: 0,
        reward: null,
        params: null
      },
      options: {
        headers: ['id', 'name', 'docs'],
        column: null,
        search: null,
        toolbar: null,
      },
      rFields: ['options'],
      tickets: [],
    }
  },
  apollo: {
    tickets: {
      query () {
        return LIST_TICKET(this.templateType)
      },
      variables () {
        return {
          couponType: this.couponType,
          couponId: this.couponId
        }
      },
      fetchPolicy: 'network-only',
      skip () {
        return !this.couponId
      }
    }
  },
  methods: {
    generateTicket () {
      this.$apollo.mutate({
        mutation: GENERATE_TICKET(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$apollo.queries.tickets.refetch()
      })
      .catch(this.$toasted.global.error)
    },
    revokeTicket() {
      this.$apollo.mutate({
        mutation: REVOKE_TICKET(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.$apollo.queries.tickets.refetch()
      })
      .catch(this.$toasted.global.error)
    },
    deactivateTicket(couponTicketId) {
      this.$apollo.mutate({
        mutation: DEACTIVATE_TICKET(this.templateType),
        variables: {
          couponType: this.couponType,
          couponTicketId
        }
      })
      .then(() => {
        this.$toasted.global.success("ยกเลิกสำเร็จ")
        this.$apollo.queries.tickets.refetch()
      })
      .catch(this.$toasted.global.error)
    },
    closeTicket(couponTicketId) {
      this.$apollo.mutate({
        mutation: CLOSE_TICKET(this.templateType),
        variables: {
          couponType: this.couponType,
          couponTicketId
        }
      })
        .then(() => {
          this.$toasted.global.success("ยกเลิกสำเร็จ")
          this.$apollo.queries.tickets.refetch()
        })
        .catch(this.$toasted.global.error)
    },
    rollbackTicket(couponTicketId) {
      this.$apollo.mutate({
        mutation: ROLLBACK_TICKET(this.templateType),
        variables: {
          couponType: this.couponType,
          couponTicketId
        }
      })
        .then(() => {
          this.$toasted.global.success("ยกเลิกสำเร็จ")
          this.$apollo.queries.tickets.refetch()
        })
        .catch(this.$toasted.global.error)
    },
    toDoc(doc) {
      return {
        name: `Doc${this.$form.capitalize(doc.type)}Detail`,
        params: { docId: doc.id },
        query: { redirect: this.$route.name }
      }
    },
    getLink(ticketId) {
      const url = this.formData.url
      if (!url) {
        this.$toasted.global.error('ไม่พบการตั้งค่า URL')
        return
      }
      const link = url
        .replace(/:couponType/g, this.couponType)
        .replace(/:couponId/g, this.couponId)
        .replace(/:couponCode/g, this.formData.code || '')
        .replace(/:couponTicketId/g, ticketId)

      this.$copyText(link)
      this.$toasted.global.success('คัดลอก Link สำเร็จ')
    },
  },
  watch: {
    'formData.approvedAt': {
      handler () {
        this.$apollo.queries.tickets.refetch()
      }
    },
    options: 'setRetaining'
  }
}
</script>

<style lang="css">
</style>
