<template>
  <sgv-table
    :items="rewards"
    :headers="headers"
    :filter.sync="filter"
    :toolbars="toolbars"
    :options.sync="options">

    <template slot="option" v-if="options.toolbar === 'add'">
      <div class="form-row">
        <sgv-input-textarea
          :disabled="formData.approvedAt"
          class="col-12"
          label="ชื่อ"
          autoGrow
          v-model="addFormData.name"
          :validations="[
            {text: 'required!', value: $v.addFormData.name.$dirty && $v.addFormData.name.$error}
          ]">
        </sgv-input-textarea>

        <DetailRewardDropdownInventory
          :disabled="formData.approvedAt"
          class="col-12"
          label="สินค้า"
          v-model="addFormData.inventoryId"
          :couponType="couponType"
          :templateType="templateType"
          select="id"
          :validations="[
            {text: 'required!', value: $v.addFormData.inventoryId.$dirty && $v.addFormData.inventoryId.$error}
          ]">
        </DetailRewardDropdownInventory>

        <sgv-input-number
          :disabled="formData.approvedAt"
          class="col-6"
          label="จำนวน"
          v-model="addFormData.qty">
        </sgv-input-number>

        <sgv-input-currency
          :disabled="formData.approvedAt"
          class="col-6"
          label="ราคา/หน่วย"
          v-model="addFormData.price">
        </sgv-input-currency>
      </div>

      <!-- <div class="form-row">
        <div class="form-group col-12">
          <sgv-input-check
            :disabled="formData.approvedAt"
            label="ค่าเริ่มต้น"
            v-model="addFormData.isDefault">
          </sgv-input-check>
        </div>
      </div> -->

      <button 
        class="btn btn-success mb-3"
        @click="createReward">
        เพิ่ม
      </button>
    </template>

    <template slot-scope="{item}">
      <tr>
        <td class="pre-line">
          <fa
            v-if="!formData.approvedAt"
            icon="trash"
            class="text-danger pointer mr-1"
            @click="destroyReward(item.id)">
          </fa>

          {{item.name}}
          <small>
            <div class="text-primary">
              {{item.inventory.code}} ({{item.inventory.name}})
            </div>
          </small>
        </td>
        <td class="text-right">
          {{item.qty | comma}} {{ item.inventory.unit.name }}
        </td>
        <td class="text-right">
          {{item.price | comma}}
        </td>
        <td class="text-right">
          {{ item.qty * item.price | comma}}
        </td>
      </tr>
    </template>
  </sgv-table>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailRewardDropdownInventory from './DetailRewardDropdownInventory.vue'
import {
  LIST_REWARD,
  CREATE_REWARD,
  DESTROY_REWARD,
  WATCH_REWARD_CREATED,
  WATCH_REWARD_DESTROYED,
} from './graph/reward'

export default {
  mixins: [retainMixin],
  props: {
    couponId: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    couponType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      rKey: `Coupon${this.$form.capitalize(this.couponType)}DetailReward`,
      filter: {
        limit: null,
        offset: 0,
        reward: null,
        params: null
      },
      options: {
        headers: ['name', 'qty', 'price', 'totalPrice'],
        column: null,
        search: null,
        toolbar: null,
      },
      rewards: [],
      addFormData: {
        name: '',
        inventoryId: null,
        qty: 0,
        price: 0,
        isDefault: false
      }
    }
  },
  validations: {
    addFormData: {
      name: {required},
      inventoryId: {required},
    }
  },
  apollo: {
    rewards: {
      query () {
        return LIST_REWARD(this.templateType)
      },
      variables () {
        return {
          couponType: this.couponType,
          couponId: this.couponId
        }
      },
      fetchPolicy: 'network-only',
      skip () {
        return !this.couponId
      },
      subscribeToMore: [
        {
          document () {
            return WATCH_REWARD_CREATED(this.templateType)
          },
          variables () {
            return {
              couponType: this.couponType,
              couponId: this.couponId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const newReward = subscriptionData.data.rewardCreated
            const rewards = [
              ...previous.rewards,
              newReward
            ]

            return {...previous, rewards}
          }
        },
        {
          document () {
            return WATCH_REWARD_DESTROYED(this.templateType)
          },
          variables() {
            return {
              couponType: this.couponType,
              couponId: this.couponId
            }
          },
          updateQuery (previous, { subscriptionData }) {
            const destroyedReward = subscriptionData.data.rewardDestroyed
            const rewards = [...previous.rewards]
            const idx = rewards.findIndex(v => v.id === destroyedReward.id)

            if (idx !== -1) rewards.splice(idx, 1)

            return {...previous, rewards}
          }
        }
      ]
    }
  },
  computed: {
    headers () {
      return [
        {text: 'รายละเอียด', value: 'name'},
        {text: 'จำนวน', value: 'qty'},
        {text: 'ราคา/หน่วย', value: 'price'},
        {text: `ราคา`, value: 'totalPrice'}
      ]
    },
    toolbars () {
      let arr = []

      if (!this.formData.approvedAt && this.couponId !== '0') {
        arr.push({value: 'add', icon: 'plus', class: 'text-success'})
      }

      return arr
    }
  },
  methods: {
    createReward () {
      this.$v.addFormData.$touch()

      if (this.$v.addFormData.$invalid) return

      const input = {
        ...this.addFormData
      }

      this.$apollo.mutate({
        mutation: CREATE_REWARD(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyReward(couponRewardId) {
      this.$apollo.mutate({
        mutation: DESTROY_REWARD(this.templateType),
        variables: {
          couponType: this.couponType,
          couponRewardId
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    }
  },
  watch: {
    'formData.approvedAt': {
      handler(newValue, oldValue) {
        if (newValue && !oldValue) {
          this.options.toolbar = ''
        }
      }
    }
  },
  components: {
    DetailRewardDropdownInventory
  }
}
</script>

<style lang="css">
</style>
