<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">

    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <DetailForm
          :couponId="couponId"
          :couponType="couponType"
          :templateType="templateType"
          :formData="formData"
          :method="method"
          :v="$v"
          @updated="dataDetail">
        </DetailForm>

        <DetailApproveConfirmation
          slot="approve"
          ref="approve"
          :couponId="couponId"
          :couponType="couponType"
          :templateType="templateType"
          @update="refetchData">
        </DetailApproveConfirmation>

        <DetailCloseConfirmation
          slot="close"
          ref="close"
          :couponId="couponId"
          :couponType="couponType"
          :templateType="templateType"
          @update="refetchData">
        </DetailCloseConfirmation>
      </sgv-form>

      <template v-if="couponId !== '0'">
        <HrDivider :options="tabs" v-model="selectedTab"></HrDivider>

        <template v-for="tab in tabs">
          <component
            :couponId="couponId"
            :templateType="templateType"
            :couponType="couponType"
            :key="tab.value"
            v-if="tab.value === selectedTab"
            :is="tab.value"
            :formData="formData"
            :method="method">
          </component>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm'
import DetailApproveConfirmation from './DetailApproveConfirmation.vue'
import DetailCloseConfirmation from './DetailCloseConfirmation.vue'
import DetailReward from './DetailReward.vue'
import DetailTicket from './DetailTicket.vue'
import {
  DETAIL_COUPON, WATCH_COUPON_UPDATED,
  CREATE_COUPON, UPDATE_COUPON, DESTROY_COUPON,
  ROLLBACK_COUPON, CANCEL_COUPON
} from './graph/coupon'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.formData.code}` || `${this.title}`
    }
  },
  props: {
    couponId: {
      type: String,
      required: true
    },
    couponType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      rKey: `Coupon${this.$form.capitalize(this.couponType)}Detail`,
      listView: `Coupon${this.$form.capitalize(this.couponType)}List`,
      formData: {
        type: '',
        code: '',
        name: '',
        description: '',
        url: '',
        docTypes: '',
        ticketTotal: 0,
        ticketSample: 0,
        startedAt: null,
        expiredAt: null,
        createdAt: null,
        createdUser: null,
        approvedAt: null,
        approvedUser: null,
        closedAt: null,
        closedUser: null,
      },
      isLoaded: false,
      rFields: ['selectedTab'],
      selectedTab: 'DetailTicket'
    }
  },
  validations: {
    formData: {
      name: { required },
      description: { required },
      url: { required },
      startedAt: { required },
      expiredAt: { required },
    }
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_COUPON_UPDATED(this.templateType)
        },
        variables () {
          return {
            couponType: this.couponType,
            couponId: this.couponId
          }
        },
        result (res) {
          this.setFormData(res.data.couponUpdated)
        },
      },
    },
  },
  computed: {
    tabs() {
      let arr = [
        { text: 'คูปอง', value: 'DetailTicket' },
        { text: 'รางวัล', value: 'DetailReward' },
      ]
      return arr
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`coupon:${this.couponType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`coupon:${this.couponType}:add`)
        },
        {
          text: 'อนุมัติ',
          variant: 'warning',
          method: 'approve',
          func: this.approveData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`coupon:${this.couponType}:approve`)
        },
        {
          text: 'ยกเลิก',
          variant: 'info',
          method: 'cancel',
          func: this.cancelData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && !this.formData.closedAt) || !this.$auth.hasRole(`coupon:${this.couponType}:approve`)
        },
        {
          text: 'จบ',
          variant: 'success',
          method: 'close',
          func: this.closeData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && !this.formData.closedAt) || !this.$auth.hasRole(`coupon:${this.couponType}:close`)
        },
        {
          text: 'ย้อนกลับ',
          variant: 'info',
          method: 'rollback',
          func: this.rollbackData,
          disabled: this.method === 'add' || !this.formData.closedAt || !this.$auth.hasRole(`coupon:${this.couponType}:close`)
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_COUPON(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.coupon)})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (['startedAt', 'expiredAt'].includes(key)) {
          this.formData[key] = this.$date.format(v[key]).datetime
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeInput (v) {
      const input = {...v}
      const removeKeys = [
        'type', 'createdAt', 'approvedAt', 'closedAt',
        'createdUser', 'approvedUser', 'closedUser'
      ]
      removeKeys.forEach(x => delete input[x])

      if (input.startedAt) input.startedAt = this.$date.format(input.startedAt).raw
      if (input.expiredAt) input.expiredAt = this.$date.format(input.expiredAt).raw

      return input
    },
    createData () {
      this.$v.formData.$touch()

      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_COUPON(this.templateType),
        variables: {
          couponType: this.couponType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, couponId: res.data.coupon.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()

      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_COUPON(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_COUPON(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    approveData () {
      this.$refs.approve.approveData()
    },
    cancelData () {
      this.$apollo.mutate({
        mutation: CANCEL_COUPON(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId,
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("ยกเลิกสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    closeData () {
      this.$refs.close.closeData()
    },
    rollbackData () {
      this.$apollo.mutate({
        mutation: ROLLBACK_COUPON(this.templateType),
        variables: {
          couponType: this.couponType,
          couponId: this.couponId,
        }
      })
      .then(() => {
        this.refetchData()
        this.$toasted.global.success("ย้อนรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    refetchData () {
      this.method = 'info'
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    },
  },
  watch: {
    selectedTab: 'setRetaining'
  },
  created () {
    if (this.couponId == '0') {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    DetailApproveConfirmation,
    DetailCloseConfirmation,
    DetailReward,
    DetailTicket
  }
}
</script>

<style lang="css">
</style>
